import React from "react";
import { Transition } from "@headlessui/react";
import { Fragment, useState, useRef } from "react";
import { useTimeoutFn } from "react-use";
import logoPink from "../../../assets/logos/the-edit-logo-pink.png";
import Button from "../buttons/Button";
import Card from "./Card";
import SubscribeForm from "../forms/SubscribeForm";

export default function SubscribeCard() {
  const [showSubscribeForm, setShowSubscribeForm] = useState(false);
  const [isShowing, setIsShowing] = useState(true);
  const [, , resetIsShowing] = useTimeoutFn(() => setIsShowing(true), 500);
  const cardRef = useRef(null);

  const openSubscribeFormHandler = () => {
 
    const cardContainer = document.getElementById("subscribeForm");
    if (cardContainer) {
      cardContainer.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    // onFormOpen();
    setIsShowing(false);
    setTimeout(() => {
      setShowSubscribeForm(true);
    }, 500);
    resetIsShowing();
  };

  const closeSubscribeFormHandler = () => {
    setShowSubscribeForm(false);
    setIsShowing(true);
  };


  return (
    <>
      {!showSubscribeForm && (
        <div className="flex flex-col items-center py-16 md:py-0">
          <Transition
            as={Fragment}
            show={isShowing}
            enter="transition transform duration-400"
            enterFrom="opacity-0 rotate-[-120deg] scale-50"
            enterTo="opacity-100 rotate-0 scale-100"
            leave="transition transform duration-200 ease-in-out"
            leaveFrom="opacity-100 rotate-0 scale-100"
            leaveTo="opacity-0 rotate-[-120deg] scale-50"
            afterLeave={() => setShowSubscribeForm(true)}
          >
            <div ref={cardRef}>
              <Card
                className={`subscribe-card flex flex-col justify-center bg-black mt-0 mb-12 pb-24 md:pt-24 md:mt-8 lg:pt-0 w-full md:rounded-lg transition-all`}
                id="subscribeForm"
              >
                <div className="card-container relative top-0">
                  <div className="flex flex-col justify-start text-center mt-28 md:p-8 md:mt-12">
                    <div className="flex flex-col justify-center items-center">
                      <a
                        href="https://www.superdrug.com/the-edit"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={logoPink}
                          alt="the edit subscribe logo"
                          width="150px"
                          height="auto"
                        />
                      </a>
                      <h2 className="text-white text-2xl font-bold mb-2 p-4">
                        Subscribe to our mailing list
                      </h2>
                      <p className="text-white text-lg w-3/4">
                        Get great features, exciting competitions, and Superdrug
                        promotions straight to your inbox!
                      </p>
                      <Button
                        className="subscribe-btn text-white w-1/2 sm:w-1/4 lg:w-1/3 m-auto mt-8 md:mt-12 text-md text-lg md:text-xl lg:text-2xl"
                        onClick={openSubscribeFormHandler}
                      >
                        Subscribe
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </Transition>
        </div>
      )}
      {showSubscribeForm && (
        <SubscribeForm
          id="subscribeForm"
          onClose={closeSubscribeFormHandler}
          setError={() => {}}
          setSuccess={() => {}}
          setIsShowing={setIsShowing}
          resetIsShowing={resetIsShowing}
          onFormSubmit={closeSubscribeFormHandler}
        />
      )}
    </>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) and (max-width:769px) {
  .comp__header {
    margin-left: 24px;
  }

  .comp__tag {
    margin-left: 24px;
  }
}

@media (min-width: 820px) and (max-width: 821px) {
  .comp__header {
    margin-left: 4px;
  }

  .comp__tag {
    margin-left: 4px;
  }
}

@media (min-width: 1023px) and (max-width: 1024px) {
  .comp__header {
    margin-left: 30px;
  }

  .comp__tag {
    margin-left: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Competitions.css"],"names":[],"mappings":"AAAA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["@media (min-width: 768px) and (max-width:769px) {\n  .comp__header {\n    margin-left: 24px;\n  }\n\n  .comp__tag {\n    margin-left: 24px;\n  }\n}\n\n@media (min-width: 820px) and (max-width: 821px) {\n  .comp__header {\n    margin-left: 4px;\n  }\n\n  .comp__tag {\n    margin-left: 4px;\n  }\n}\n\n@media (min-width: 1023px) and (max-width: 1024px) {\n  .comp__header {\n    margin-left: 30px;\n  }\n\n  .comp__tag {\n    margin-left: 30px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, {useState} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useForm, ValidationError } from "@formspree/react";

export const MailingForm = ({
  setError,
  setSuccess,
  nameChangeHandler,
  surnameChangeHandler,
  emailChangeHandler,
  enteredName,
  enteredSurname,
  enteredEmail,
  onFormSubmit,
}) => {
  const privacyUrl =
    process.env.PUBLIC_URL + "/pdf/The-Edit-Privacy-Notice-V2.pdf";
  const subscribeEndpoint = process.env.REACT_APP_FORMSPREE_SUBSCRIBE_ENDPOINT;
  const formID = process.env.REACT_APP_FORMSPREE_SUBSCRIBE_KEY;
  const [state, _handleSubmit] = useForm(formID);
  const [isChecked, setIsChecked] = useState(false);
  // const [isFormValid, setIsFormValid] = useState(false);

  const openPrivacyPDF = (e) => {
    e.preventDefault();
    window.open(privacyUrl, "_blank");
  };

  const handleSubscribeFormSubmit = async (event) => {
    event.preventDefault();

    // new form data
    const formData = new FormData(event.target);

    if (
      !enteredName ||
      !enteredSurname ||
      !enteredEmail ||
      enteredName.trim().length === 0 ||
      enteredSurname.trim().length === 0 ||
      enteredEmail.trim().length === 0
    ) {
 
      setError({
        title: "Invalid input",
        message: "Please enter a valid name and email.",
      });
      return;
    }
    try {
      const formEndpoint = `${subscribeEndpoint}`;
      const response = await axios.post(formEndpoint, formData);

      if (response.status === 200) {
        // successful
        setSuccess(true);
        onFormSubmit();
        // clear form
        event.target.reset();
      } else {
        // if error
        setError({
          title: "Form submission error",
          message:
            "An error occurred while submitting the form. Please try again later.",
        });
      }
    } catch (error) {
      // Error
      setError({
        title: "Form submission error",
        message:
          "An error occurred while submitting the form. Please try again later.",
      });
      console.error("Error:", error);
    }
  };

  return (
    <form onSubmit={handleSubscribeFormSubmit}>
      <label htmlFor="name">First name</label>
      <input
        id="name"
        type="text"
        name="name"
        onChange={nameChangeHandler}
        value={enteredName}
      />

      <label htmlFor="surname">Surname</label>
      <input
        id="surname"
        type="text"
        name="surname"
        onChange={surnameChangeHandler}
        value={enteredSurname}
      />

      <label htmlFor="email">Email</label>
      <input
        id="email"
        type="email"
        name="email"
        onChange={emailChangeHandler}
        value={enteredEmail}
      />
      <ValidationError prefix="Name" field="name" errors={state.errors} />
      <ValidationError prefix="Surname" field="surname" errors={state.errors} />
      <ValidationError prefix="Email" field="email" errors={state.errors} />

      {/* PP compliance checkbox */}
      <div className="flex flex-col items-start mt-8">
        <a
          className="card__privacy-link text-teal-300 text-sm sm:text-md text-left underline hover:text-sd-aqua"
          target="_blank"
          rel="noopener noreferrer"
          href={privacyUrl}
          onClick={openPrivacyPDF}
        >
          Privacy Policy
        </a>
        {/* checkbox confirmation */}
        <div className="flex justify-start items-center">
          <label className="flex items-start mt-4 mr-4">
            <input
              name="checkbox"
              type="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              className="h-6 w-6 mr-2 cursor-pointer"
            />
          </label>
          <span className="card__checkbox-label text-white text-sm sm:text-md md:text-lg">
            I agree to receive emails from The Edit Magazine
          </span>
        </div>
      </div>
      <button
        type="submit"
        className={`button mt-8 my-4 py-2 px-12 border-2 rounded-lg ${
          !isChecked
            ? "disabled border-item-disabled text-item-disabled"
            : "border-pink-nav text-pink-nav animate-bounce"
        }`}
        disabled={!isChecked}
      >
        Submit
      </button>
    </form>
  );
};

MailingForm.propTypes = {
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  nameChangeHandler: PropTypes.func.isRequired,
  surnameChangeHandler: PropTypes.func.isRequired,
  emailChangeHandler: PropTypes.func.isRequired,
  enteredName: PropTypes.string.isRequired,
  enteredSurname: PropTypes.string.isRequired,
  enteredEmail: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

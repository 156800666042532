import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const TsContainer = ({ children }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [pixelRatio, setPixelRatio] = useState(window.devicePixelRatio);
  const isIPadPro = screenWidth === 1024 && pixelRatio === 2;

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setPixelRatio(window.devicePixelRatio);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="container flex justify-center"
      style={{ maxWidth: "1640px" }}
    >
      <div className="comp__inner container">
        <div
          className={`flex ${
            screenWidth === 1024 && !isIPadPro ? "flex-col" : ""
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

TsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TsContainer;

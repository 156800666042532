import React from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import styles from "./ModalPopup.module.css";
import ModalCard from "./ModalCard";
import Button from "../../ui/buttons/Button";

const Backdrop = (props) => {
  return <motion.div className={styles.backdrop} onClick={props.onConfirm} />;
};

Backdrop.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

const ModalOverlay = (props) => {
  return (
    <motion.div
      className={styles.modal}
      tabIndex={-1}
      role="dialog"
      aria-modal="true"
    >
      <ModalCard className={styles.modal}>
        <header className={styles.header}>
          <h2>{props.title}</h2>
        </header>
        <div className={styles.content}>
          <p>{props.message}</p>
        </div>
        <footer className={styles.actions}>
          <Button
            className="button border-primary text-primary py-2 px-8"
            onClick={props.onConfirm}
          >
            Ok
          </Button>
        </footer>
      </ModalCard>
    </motion.div>
  );
};

ModalOverlay.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const Modal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onConfirm={props.onConfirm} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          title={props.title}
          message={props.message}
          onConfirm={props.onConfirm}
        />,
        document.getElementById("overlay-root")
      )}
    </>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default Modal;

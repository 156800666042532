import React, { useState } from "react";
import PropTypes from "prop-types";
import CardContainer from "./CardContainer";
import { ImageVariants } from "./ImageVariants";
import "./CompCards.css";

import { formNames } from "../../../lib/constants/constants";

const CompCards = ({ data }) => {
    const [_selectedCard, setSelectedCard] = useState(null);

    const handleFormOpen = (cardId) => {
        setSelectedCard(cardId);
    };

    const handleFormClose = () => {
        setSelectedCard(null);
    };

    const competitions = data;

    return (
        <div className="card-container mt-8 mb-0">
            <div className="comp-cards__inner rounded-lg">
                {competitions.map((competition, index) => {
                    const imageVariant = ImageVariants[competition.id];
                    if (!imageVariant) {
                        return null; // Skip this competition if ImageVariant doesn't exist
                    }
                    const { smallImage, largeImage } = imageVariant;

                    return (
                        <div
                            className={`comp-cards__item container ${
                                index === competitions.length - 1
                                    ? "last-item"
                                    : ""
                            }`}
                            key={competition.id}
                        >
                            <div className="card__media transition-all duration-300">
                                <div className="card__media-inner">
                                    <img
                                        className="competition-prize mt-2"
                                        src={
                                            process.env.PUBLIC_URL + smallImage
                                        }
                                        srcSet={`${
                                            process.env.PUBLIC_URL + largeImage
                                        } 700w`}
                                        alt="the edit competition prize"
                                    />
                                </div>
                            </div>
                            <div className="card__body">
                                <CardContainer
                                    className="card__inner"
                                    title={competition.title}
                                    subtitle={competition.subtitle}
                                    description={competition.description}
                                    productUrl={competition.productUrl}
                                    termsUrl={competition.termsUrl}
                                    formName={formNames[competition.id - 1]}
                                    onFormOpen={() =>
                                        handleFormOpen(competition.id)
                                    }
                                    onFormClose={handleFormClose}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

CompCards.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            subtitle: PropTypes.string,
            description: PropTypes.string.isRequired,
            termsUrl: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default CompCards;

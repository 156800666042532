import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useIsMobile from "../../../lib/hooks/useIsMobile";
import useBooleanToggle from "../../../lib/hooks/useBooleanToggle";
import { animateScroll as scroll } from "react-scroll";
import { navList } from "./NavList";
import "./NavLinks.css";

const NavLinks = () => {
  const isMobile = useIsMobile();
  const location = useLocation();

  const [activeLink, setActiveLink] = useState(null);
  const [handleToggle] = useBooleanToggle(false);

  const handleMouseEnter = (index) => {
    setActiveLink(index);
  };

  const handleMouseLeave = () => {
    setActiveLink(null);
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 300,
      smooth: "easeInOutQuart",
    });
  };

  let navMenu = Object.values(navList);

  if (isMobile) {
    navMenu = navMenu.filter((page) => {
      return !(page.link === location.pathname && location.pathname !== "/");
    });
  }
  // const subscribeLink = navMenu.find((menu) => menu.name === "Subscribe");

  return (
    <>
      {navMenu.map((menu, index) => (
        <div className="flex items-center animate-fadeIn" key={index}>
          {isMobile && menu.icon}
          {menu.name === "Home" ? (
            <Link
              key={index}
              className="nav-links relative text-sm text-white py-6 px-4 hover:text-pink-nav transition-all duration-150"
              onClick={scrollToTop}
            >
              {menu.name}
              {activeLink === index && !isMobile && (
                <span className="triangle-shape"></span>
              )}
            </Link>
          ) : menu.name === "Subscribe" ? (
            <Link
              key={navList.subscribe.name}
              onClick={(e) => {
                e.preventDefault();
                const subscribeSection = document.getElementById("subscribe");
                if (subscribeSection) {
                  window.scrollTo({
                    top: subscribeSection.offsetTop,
                    behavior: "smooth",
                  });
                }
              }}
              to={navList.subscribe.link}
              className={`nav-links subscribe-link relative text-sm text-white font-bold py-2 px-4
              hover:text-black border-2 border-pink-nav bg-pink-nav hover:bg-white hover:border-pink-nav rounded-full transition-all duration-300 ${
                isMobile && "ml-4 mt-4"
              }
              `}
              onMouseEnter={() => handleMouseEnter(navMenu.length)}
              onMouseLeave={handleMouseLeave}
            >
              {navList.subscribe.name}
              {activeLink === navMenu.length && !isMobile && (
                <span className="triangle-shape sub"></span>
              )}
            </Link>
          ) : (
            <Link
              key={index}
              onClick={handleToggle ? handleToggle : undefined}
              to={menu.link}
              className={`nav-links relative text-sm text-white py-6 lg:py-3 px-4 hover:text-pink-nav transition-all duration-150`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              target="_blank"
              rel="noopener noreferrer"
            >
              {menu.name}
              {activeLink === index && !isMobile && (
                <span className="triangle-shape"></span>
              )}
            </Link>
          )}
        </div>
      ))}
    </>
  );
};

export default NavLinks;

import React, { useState } from "react";
import { FormspreeProvider } from "@formspree/react";
import PropTypes from "prop-types";
import Card from "../cards/Card";
import Modal from "../../common/modal/ModalPopup";
import styles from "./FormStyles.module.css";

import { MailingForm } from "./MailingForm";

function SubscribeForm({ onClose, setIsShowing, resetIsShowing }) {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [enteredName, setEnteredName] = useState("");
  const [enteredSurname, setEnteredSurname] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [isFormOpen, setIsFormOpen] = useState(true);

  const mailingID = process.env.REACT_APP_FORMSPREE_SUBSCRIBE_ENDPOINT;

  const errorHandler = () => {
    setError(null);
  };

  const handleFormSubmit = () => {
    setIsShowing(false);
    resetIsShowing();
    setEnteredName("");
    setEnteredSurname("");
    setEnteredEmail("");
    setSuccess(true);
    setIsFormOpen(false);
  };

  const handleCloseForm = () => {
    setSuccess(false);
    setIsFormOpen(false);
    onClose();
    setIsShowing(true);
  };

  // return mailingID;

  return (
    <div className="subscribe-form w-full md:w-3/4 lg:w-1/2 transition-all duration-300 md:border-2 rounded-lg my-16">
      {error && (
        <Modal
          title={error.title}
          message={error.message}
          onConfirm={errorHandler}
        />
      )}
      {success ? (
        <Modal
          title="Form Submitted Successfully"
          message="Thank you for subscribing to The Edit!"
          onConfirm={() => setSuccess(false)}
        />
      ) : (
        <FormspreeProvider className="subscribe" formID={mailingID}>
          <Card className={`${styles.input}`} id="subscribe-card">
            <div className={styles.formHeader}>
              <h2
                className={`${styles.formId} text-3xl sm:text-4xl md:text-5xl`}
              >
                Subscribe to
                <br />
                The Edit
              </h2>
              <button
                className={styles.closeSubscribeButton}
                onClick={handleCloseForm}
              >
                X
              </button>
            </div>
            <div className={`${styles.formTitle} mt-4 mx-4`}>
              Enter Your Details
              <p className={styles.p}>
                <b>to receive all the latest news!</b>
              </p>
            </div>
            <div className="bg-black rounded-lg mx-2 p-8 pt-10 pb-8">
              <MailingForm
                setError={setError}
                setSuccess={setSuccess}
                nameChangeHandler={(event) =>
                  setEnteredName(event.target.value)
                }
                surnameChangeHandler={(event) =>
                  setEnteredSurname(event.target.value)
                }
                emailChangeHandler={(event) =>
                  setEnteredEmail(event.target.value)
                }
                enteredName={enteredName}
                enteredSurname={enteredSurname}
                enteredEmail={enteredEmail}
                onFormSubmit={handleFormSubmit}
              />
            </div>
          </Card>
        </FormspreeProvider>
      )}
    </div>
  );
}

SubscribeForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  setIsShowing: PropTypes.func.isRequired,
  resetIsShowing: PropTypes.func.isRequired,
};

export default SubscribeForm;

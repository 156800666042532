import React from "react";
import { Link } from "react-router-dom";
import editLogo from "../../../assets/logos/the-edit-logo-white.png";
import superLogo from "../../../assets/logos/logo-Superdrug.png";
const Logos = () => {
  // cancelled due to Edge incompatibility 
  // const superLogo = process.env.PUBLIC_URL + "/logo-Superdrug.avif";

  return (
    <div className="flex justify-between sm:ml-2 md:ml-6">
      <Link to="/" className="flex items-center">
        <img
          className="h-full mr-8 z-50"
          src={`${editLogo}`}
          width="120px"
          alt="The Edit Magazine logo icon"
        />
      </Link>
      <Link to="/" className="flex items-center">
        <img
          className="hidden md:flex h-full mb-2 z-50"
          src={`${superLogo}`}
          alt="Superdrug logo icon"
        />
      </Link>
    </div>
  );
};

export default Logos;

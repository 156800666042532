import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import SubscribeCard from "./SubscribeCard";

const Subscribe = ({ sectionRefs }) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    if (sectionRefs && sectionRef.current) {
      sectionRefs.current.push(sectionRef);
    }
  }, [sectionRefs, sectionRef]);

  return (
    <section ref={sectionRef} className="section subscribe mt-0 mb-12 md:mb-8">
      <div
        className="form-container flex justify-center mb-0 lg:p-8 lg:py-0"
        id="subscribe"
      >
        <SubscribeCard sectionRefs={sectionRefs} />
      </div>
    </section>
  );
};

Subscribe.propTypes = {
  sectionRefs: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default Subscribe;

import { useEffect, useRef } from 'react';

const useScrollPosition = (refCallback) => {
  const sectionRefs = useRef([]);

  useEffect(() => {
    const handleScrollAnimations = () => {
      const scrollPosition = window.scrollY + window.innerHeight;

      sectionRefs.current.forEach((sectionRef) => {
        if (sectionRef.current) {
          const position = sectionRef.current.offsetTop;

          if (scrollPosition > position) {
            sectionRef.current.classList.add('animate-fadeUpSlide');
          } else {
            sectionRef.current.classList.remove('animate-fadeUpSlide');
          }
        }
      });
    };

    const handleLoad = () => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    };

    window.addEventListener('scroll', handleScrollAnimations);
    window.addEventListener('DOMContentLoaded', handleLoad);
    window.addEventListener('load', () => {
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 0);
    });

    return () => {
      window.removeEventListener('scroll', handleScrollAnimations);
      window.removeEventListener('DOMContentLoaded', handleLoad);
    };
  }, []);

  useEffect(() => {
    if (typeof refCallback === 'function') {
      refCallback(sectionRefs);
    }
  }, [refCallback, sectionRefs]);

  return sectionRefs;
};

export default useScrollPosition;







import React from "react";
export const navList = {
  home: {
    icon: (
      <i className="fa-solid fa-house sm ml-5" style={{ color: "#ffffff" }}></i>
    ),
    to: "home",
    name: "Home",
    link: "/",
  },
  theEdit: {
    icon: (
      <i
        className="fa-solid fa-newspaper sm ml-5"
        style={{ color: "#ffffff" }}
      ></i>
    ),
    link: "https://www.superdrug.com/the-edit",
    name: "The Edit",
  },
  contact: {
    icon: (
      <i
        className="fa-solid fa-paper-plane sm ml-5"
        style={{ color: "#ffffff" }}
      ></i>
    ),
    link: "mailto:tobungu@therivergroup.co.uk?cc=sturner@therivergroup.co.uk",
    name: "Contact",
  },
  subscribe: {
    // icon: (
    //   <i
    //     className="fa-solid fa-paper-plane sm ml-5 mr-4"
    //     style={{ color: "#ffffff" }}
    //   ></i>
    // ),
    link: "subscribe",
    name: "Subscribe",
  },
};

import React from "react";
import useIsMobile from "../../../lib/hooks/useIsMobile";
import useBooleanToggle from "../../../lib/hooks/useBooleanToggle";
import NavLinks from "./NavLinks";
import styles from "./Nav.module.css";
import { motion } from "framer-motion";

const NavToggle = () => {
  const [isToggleOn, toggle] = useBooleanToggle(false);
  const isMobile = useIsMobile();

  const handleOverlayClick = () => {
    toggle();
  };
  const BurgerBar = `h-1 w-7 bg-white rounded-md`;
  return (
    <>
      {useIsMobile() && (
        // nav toggle
        <div className="mobile-nav lg:hidden flex ml-auto mt-0.5 md:mr-5 z-50">
          <button
            type="button"
            className={`hamburger -m-2.5 inline-flex items-center rounded-md p-2.5 ${
              isToggleOn ? "cross" : ""
            }`}
            onClick={toggle}
          >
            {/* hamburger */}
            <div className="hamburger-toggle space-y-1">
              <motion.div
                className={`${BurgerBar} ${
                  isToggleOn
                    ? "-rotate-45 -translate-y-2 md:translate-y-3 opacity-100 group-hover:opacity-80"
                    : "rotate-0 opacity-100 group-hover:opacity-80"
                }`}
                initial={false}
                animate={{
                  rotate: isToggleOn ? -45 : 0,
                  y: isToggleOn ? (isMobile ? 8 : -3) : 0,
                }}
              />
              <motion.div
                className={`${BurgerBar} ${
                  isToggleOn
                    ? "opacity-0"
                    : "opacity-100 group-hover:opacity-80"
                }`}
                initial={false}
                animate={{ opacity: isToggleOn ? 0 : 1 }}
              />
              <motion.div
                className={`${BurgerBar} ${
                  isToggleOn
                    ? "-rotate-45 -translate-y-2 md:translate-y-3 opacity-100 group-hover:opacity-80"
                    : "rotate-0 opacity-100 group-hover:opacity-80"
                }`}
                initial={false}
                animate={{
                  rotate: isToggleOn ? 45 : 0,
                  y: isToggleOn ? (isMobile ? -8 : 3) : 0,
                }}
              />
            </div>
          </button>
        </div>
      )}
      {/* overlay dropdown */}
      {isToggleOn && (
        <>
          <div
            className={`overlay fixed top-20 left-0 w-full h-[360px] bg-black opacity-80 animate-slideDown z-40`}
            onClick={handleOverlayClick}
          >
            <motion.div
              className={`mobile-links fixed top-[35px] left-3 sm:left-14 md:left-16 w-full h-full flex flex-col z-50
              ${styles.open}`}
              initial="hidden"
              animate="visible"
              variants={{
                hidden: { opacity: 0, y: -100 },
                visible: { opacity: 1, y: 0 },
              }}
            >
              <NavLinks />
            </motion.div>
          </div>
        </>
      )}
    </>
  );
};

export default NavToggle;

import React from "react";
import PropTypes from "prop-types";
import styles from "./ModalCard.module.css";

const ModalCard = (props) => {
  return (
    <div className={`${styles.card} ${props.className}`}>{props.children}</div>
  );
};

ModalCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ModalCard;

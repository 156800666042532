// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.triangle-shape {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 11%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 15px 10px;
  border-color: transparent transparent var(--pink-nav) transparent;
}

.sub {
  margin-top: 13%;
}

.nav-links {
  font-size: 16px;
  text-decoration: none;
  /* color: var(--text-light); */
  cursor: pointer;
}

.nav-links:last-child {
  margin-right: 0;
}

/* .nav-links:last-child:hover {
  color: var(--text-dark);
} */

/* .nav-links:hover {
  color: var(--pink-nav);
} */`, "",{"version":3,"sources":["webpack://./src/components/ui/navigation/NavLinks.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,2BAA2B;EAC3B,eAAe;EACf,QAAQ;EACR,SAAS;EACT,mBAAmB;EACnB,8BAA8B;EAC9B,iEAAiE;AACnE;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EAIf,qBAAqB;EACrB,8BAA8B;EAC9B,eAAe;AALjB;;AAQA;EACE,eAAe;AACjB;;AAEA;;GAEG;;AAEH;;GAEG","sourcesContent":[".triangle-shape {\n  position: absolute;\n  top: 100%;\n  left: 50%;\n  transform: translateX(-50%);\n  margin-top: 11%;\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 0 10px 15px 10px;\n  border-color: transparent transparent var(--pink-nav) transparent;\n}\n\n.sub {\n  margin-top: 13%;\n}\n\n.nav-links {\n  font-size: 16px;\n}\n\n.nav-links {\n  text-decoration: none;\n  /* color: var(--text-light); */\n  cursor: pointer;\n}\n\n.nav-links:last-child {\n  margin-right: 0;\n}\n\n/* .nav-links:last-child:hover {\n  color: var(--text-dark);\n} */\n\n/* .nav-links:hover {\n  color: var(--pink-nav);\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import PropTypes from "prop-types";
import CompetitionForm from "../forms/CompetitionForm";
import Card from "./Card";
import "./CardContainer.css";

const CardContainer = ({
    title,
    description,
    // productUrl,
    termsUrl,
    onFormOpen,
    formName,
}) => {
    const [showForm, setShowForm] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const openFormHandler = () => {
        setShowForm(true);
        setIsFormOpen(true);
        const cardContainer = document.getElementById("compForm");
        if (cardContainer) {
            cardContainer.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
        onFormOpen();
    };

    const closeFormHandler = () => {
        setShowForm(false);
        setIsFormOpen(false);
        onFormOpen(null);
    };

    const openTermsPDF = (e) => {
        e.preventDefault();
        window.open(termsUrl, "_blank");
    };

    return (
        <>
            {!showForm && (
                <Card
                    className="card flex flex-col justify-start text-center z-30"
                    id="compCard"
                >
                    <div>
                        <h2 className="card__title">{title}</h2>
                        {/* <p className="card__description">{description}</p> */}
                        <p
                            className="card__description"
                            dangerouslySetInnerHTML={{ __html: description }}
                        ></p>
                        {/* additional URL product links */}
                        {/* these MUST be mapped per product URL */}
                        {/* <p className="card__product-links text-left ml-2 mb-8">
                            Shop the range {" "}
                            <a 
                                className="card__product-url" 
                                href={productUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >here</a>
                        </p> */}
                    </div>
                    <div className="card__bottom text-left">
                        <a
                            className="card__terms-link text-teal-300 hover:text-sd-aqua"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={termsUrl}
                            onClick={openTermsPDF}
                        >
                            Terms and Conditions
                        </a>
                        {/* checkbox confirmation */}
                        <label className="card__checkbox flex flex-start m-2">
                            <input
                                className="hover:cursor-pointer"
                                name="checkbox"
                                type="checkbox"
                                checked={isChecked}
                                onChange={() => setIsChecked(!isChecked)}
                            />
                            <span className="card__checkbox-label text-white ml-2 text-xs">
                                I agree to the terms and conditions
                            </span>
                        </label>
                        <button
                            className={`card__button card__form-link button border-none rounded-lg w-1/2 py-2 px-8 ${
                                !isChecked ? "disabled" : "hover:animate-float"
                            }`}
                            onClick={openFormHandler}
                            disabled={!isChecked}
                        >
                            Enter Now
                        </button>
                    </div>
                </Card>
            )}
            {showForm && (
                <CompetitionForm
                    id="compForm"
                    onClose={closeFormHandler}
                    setError={() => {}}
                    setSuccess={() => {}}
                    index={formName}
                    setIsFormOpen={setIsFormOpen}
                />
            )}
        </>
    );
};

CardContainer.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    // productUrl: PropTypes.string.isRequired,
    termsUrl: PropTypes.string.isRequired,
    onFormOpen: PropTypes.func.isRequired,
    formName: PropTypes.string.isRequired,
};

export default CardContainer;

import { React, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/layout/Header';
import MainPage from './pages/MainPage.jsx';
import Footer from './components/layout/Footer';
import './App.css';

function App() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Router>
          <Header />
          <Routes>
            <Route path="/" element={<MainPage />} index={true} />
          </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import Logos from "../../common/logo/Logos";
import NavLinks from "./NavLinks";
import NavToggle from "./NavToggle";
import useIsMobile from "../../../lib/hooks/useIsMobile";

const Nav = () => {
  const isMobile = useIsMobile();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

useEffect(() => {
  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos < 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const isScrolledDown = prevScrollPos < currentScrollPos;
      setVisible(!isScrolledDown);
      setPrevScrollPos(currentScrollPos);
    }
  };

  window.addEventListener("scroll", handleScroll);

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, [prevScrollPos]);

  return (
    <nav
      className={`navbar px-8 sm:px-16 py-3 flex justify-between items-center w-full fixed top-0 left-0 z-40 bg-background-dark border-b-4 border-pink-nav transition-all duration-300 ${
        isMobile && visible ? "navbar-mobile" : "navbar-desktop"
      }`}
      // or, always show/hide - force style
      style={{
        opacity: visible ? 1 : 0,
        visibility: visible ? "visible" : "hidden",
        overscrollBehaviorY: isMobile ? "contain" : "auto",
      }}
    >
      <div className="nav__inner flex justify-between items-center w-full">
        <div className="nav-logos">
          <Logos />
        </div>

        {/* Conditionally render NavLinks and NavToggle */}
        {!isMobile && (
          <div className="hidden lg:flex lg:gap-x-6 z-50 ml-auto transition-opacity animate-fadeIn">
            <NavLinks />
          </div>
        )}

        {isMobile && <NavToggle />}
      </div>
    </nav>
  );
};

export default Nav;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-font {
  font-family: "CustomFont", sans-serif;
  font-feature-settings: "lnum";
  font-variant-numeric: lining-nums;
}

.hero {
  margin-top: 20vh;
}

@media (min-width: 1279px) {
  .hero {
    margin-top: 15vh;
  }
}

@media (max-width: 1024px) {
  .hero {
    margin-top: 15vh;
  }
}

@media (max-width: 540px) {
  .hero {
    margin-top: 15vh;
  }
}

@media (max-width: 375px) {
  .hero {
    margin-top: 15vh;
  }
}

@media (max-width: 360px) {
  .hero {
    margin-top: 15vh;
  }
}

@media (max-width: 320px) {
  .hero {
    margin-top: 15vh;
  }

  .hero__header {
    font-size: 36px;
  }

  .hero__text {
    font-size: 16px;
  }
}

@media (min-width: 1228px) and (max-width: 1600px) {
  .hero__header {
    font-size: 5rem;
  }
}

@media (min-width: 1023px) and (max-width: 1024px) {
  .hero {
    margin-top: 10vh;
    padding: 0 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Hero.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,6BAAiC;EAAjC,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,eAAe;EACjB;AACF","sourcesContent":[".custom-font {\n  font-family: \"CustomFont\", sans-serif;\n  font-variant-numeric: lining-nums;\n}\n\n.hero {\n  margin-top: 20vh;\n}\n\n@media (min-width: 1279px) {\n  .hero {\n    margin-top: 15vh;\n  }\n}\n\n@media (max-width: 1024px) {\n  .hero {\n    margin-top: 15vh;\n  }\n}\n\n@media (max-width: 540px) {\n  .hero {\n    margin-top: 15vh;\n  }\n}\n\n@media (max-width: 375px) {\n  .hero {\n    margin-top: 15vh;\n  }\n}\n\n@media (max-width: 360px) {\n  .hero {\n    margin-top: 15vh;\n  }\n}\n\n@media (max-width: 320px) {\n  .hero {\n    margin-top: 15vh;\n  }\n\n  .hero__header {\n    font-size: 36px;\n  }\n\n  .hero__text {\n    font-size: 16px;\n  }\n}\n\n@media (min-width: 1228px) and (max-width: 1600px) {\n  .hero__header {\n    font-size: 5rem;\n  }\n}\n\n@media (min-width: 1023px) and (max-width: 1024px) {\n  .hero {\n    margin-top: 10vh;\n    padding: 0 2rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import PropTypes from "prop-types";

const Container = ({ children }) => {
  const screenWidth = window.innerWidth;
  const pixelRatio = window.devicePixelRatio;
  const isIPadPro = screenWidth === 1024 && pixelRatio === 2;

  return (
    <div
      className="flex justify-center w-screen v-screen"
      style={{ maxWidth: "1640px" }}
    >
      <div
        className={`flex ${
          screenWidth <= 1024 && !isIPadPro ? "flex-col" : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;

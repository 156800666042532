// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nav_overlay__dNhRn {
  width: 100%;
  height: auto;
  background-color: black;
}

.Nav_navbar-mobile__ziGcI {
  overscroll-behavior-y: contain;
}

.Nav_navbar-desktop__faOC9 {
  overscroll-behavior-y: auto;
}

/* .navbar-visible {
  opacity: 1;
  visibility: visible;
} */

/* .navbar-hidden {
  opacity: 0;
  visibility: hidden;
} */`, "",{"version":3,"sources":["webpack://./src/components/ui/navigation/Nav.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;;;GAGG;;AAEH;;;GAGG","sourcesContent":[".overlay {\n  width: 100%;\n  height: auto;\n  background-color: black;\n}\n\n.navbar-mobile {\n  overscroll-behavior-y: contain;\n}\n\n.navbar-desktop {\n  overscroll-behavior-y: auto;\n}\n\n/* .navbar-visible {\n  opacity: 1;\n  visibility: visible;\n} */\n\n/* .navbar-hidden {\n  opacity: 0;\n  visibility: hidden;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `Nav_overlay__dNhRn`,
	"navbar-mobile": `Nav_navbar-mobile__ziGcI`,
	"navbar-desktop": `Nav_navbar-desktop__faOC9`
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import PropTypes from "prop-types";
import { forwardRef } from "react";

const Button = forwardRef(({ children, onClick, className, type }, ref) => {
  return (
    <button
      ref={ref}
      className={`button border-2 hover:border-item-hover hover:animate-scaleUp transform transition-all duration-300
      hover:text-item-bold text-md sm:text-lg md:text-xl py-2 px-6 rounded-lg
      md:py-2 md:px-8 lg:py-3 lg:px-10 ${className}`}
      type={type || "button"}
      onClick={onClick}
    >
      {children}
    </button>
  );
});

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
};

Button.displayName = "Button";

export default Button;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalPopup_backdrop__\\+JpzU {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.ModalPopup_modal__m3cYx {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  overflow: hidden;
}

.ModalPopup_header__Zj1Wf {
  background: var(--pink-banner);
  padding: 1rem;
}

.ModalPopup_header__Zj1Wf h2 {
  margin: 0;
  color: white;
}

.ModalPopup_content__lC1Cb {
  padding: 1rem;
}

.ModalPopup_actions__ecdDV {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .ModalPopup_modal__m3cYx {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/ModalPopup.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,WAAW;EACX,+BAA+B;AACjC;;AAEA;EACE,eAAe;EACf,SAAS;EACT,SAAS;EACT,UAAU;EACV,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,SAAS;EACT,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE;IACE,uBAAuB;IACvB,YAAY;EACd;AACF","sourcesContent":[".backdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 10;\n  background: rgba(0, 0, 0, 0.75);\n}\n\n.modal {\n  position: fixed;\n  top: 30vh;\n  left: 10%;\n  width: 80%;\n  z-index: 100;\n  overflow: hidden;\n}\n\n.header {\n  background: var(--pink-banner);\n  padding: 1rem;\n}\n\n.header h2 {\n  margin: 0;\n  color: white;\n}\n\n.content {\n  padding: 1rem;\n}\n\n.actions {\n  padding: 1rem;\n  display: flex;\n  justify-content: flex-end;\n}\n\n@media (min-width: 768px) {\n  .modal {\n    left: calc(50% - 20rem);\n    width: 40rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `ModalPopup_backdrop__+JpzU`,
	"modal": `ModalPopup_modal__m3cYx`,
	"header": `ModalPopup_header__Zj1Wf`,
	"content": `ModalPopup_content__lC1Cb`,
	"actions": `ModalPopup_actions__ecdDV`
};
export default ___CSS_LOADER_EXPORT___;

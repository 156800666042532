// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iconCell {
  display: flex;
  justify-content: center;
}

.icon {
  width: auto;
  font-size: 2rem;
  margin: 1rem;
  margin-bottom: 0;
  color: var(--text-light);
}

.icon:hover {
  color: var(--sd-aqua);
}

@media (max-width: 500px) {
  .icon {
    font-size: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ui/icons/SocialIcons.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".iconCell {\n  display: flex;\n  justify-content: center;\n}\n\n.icon {\n  width: auto;\n  font-size: 2rem;\n  margin: 1rem;\n  margin-bottom: 0;\n  color: var(--text-light);\n}\n\n.icon:hover {\n  color: var(--sd-aqua);\n}\n\n@media (max-width: 500px) {\n  .icon {\n    font-size: 1rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalCard_card__V0FEb {
  margin-top: 1rem;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28);
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/ModalCard.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,yCAAyC;EACzC,mBAAmB;AACrB","sourcesContent":[".card {\n  margin-top: 1rem;\n  background: white;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28);\n  border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `ModalCard_card__V0FEb`
};
export default ___CSS_LOADER_EXPORT___;

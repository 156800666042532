import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, ValidationError } from "@formspree/react";
import axios from "axios";
import Button from "../buttons/Button";

export const CompForm = ({
    formKey,
    setError,
    setSuccess,
    nameChangeHandler,
    surnameChangeHandler,
    emailChangeHandler,
    enteredName,
    enteredSurname,
    enteredEmail,
    onFormSubmit,
}) => {
    const formProjectID = process.env.REACT_APP_FORMSPREE_PROJECT_ID;
    const [state, _handleSubmit] = useForm(formKey);
    // no re-submissions - object
    const [submittedData, setSubmittedData] = useState({});

    useEffect(() => {
        // retrieve data on component mount
        const storedData = localStorage.getItem("submittedData");
        if (storedData) {
            setSubmittedData(JSON.parse(storedData));
        }
    }, []);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        // new form data
        const formData = new FormData(event.target);
        // enteredEmail exists
        const enteredEmail = formData.get("email");

        // validation
        if (
            !enteredName ||
            !enteredSurname ||
            !enteredEmail ||
            enteredName.trim().length === 0 ||
            enteredSurname.trim().length === 0 ||
            enteredEmail.trim().length === 0
        ) {
            setError({
                title: "Invalid input",
                message: "Please enter a valid name and email.",
            });
            return;
        }

        // does data match exist
        if (
            submittedData[formKey] &&
            submittedData[formKey].includes(enteredEmail)
        ) {
            setError({
                title: "Duplicate Submission",
                message:
                    "You have already submitted an entry for this competition.",
            });
            return;
        }

        try {
            const formEndpoint = `https://formspree.io/p/${formProjectID}/f/${formKey}`;
            const response = await axios.post(formEndpoint, formData);
            //   console.log('fromEndpoint Accessed:', formEndpoint);
            if (response.status === 200) {
                // store data
                const updatedData = {
                    ...submittedData,
                    [formKey]: [
                        ...(submittedData[formKey] || []),
                        enteredEmail,
                    ],
                };
                setSubmittedData(updatedData);
                localStorage.setItem(
                    "submittedData",
                    JSON.stringify(updatedData)
                );

                setSuccess(true);
                onFormSubmit();
                event.target.reset();
            } else {
                setError({
                    title: "Form submission error",
                    message:
                        "An error occurred while submitting the form. Please try again later.",
                });
            }
        } catch (error) {
            // Error
            setError({
                title: "Form submission error",
                message:
                    "An error occurred while submitting the form. Please try again later.",
            });
            console.error("Error:", error);
        }
    };

    return (
        <form onSubmit={handleFormSubmit}>
            <label htmlFor="name">Name</label>
            <input
                id="name"
                type="text"
                name="name"
                onChange={nameChangeHandler}
                value={enteredName}
            />

            <label htmlFor="surname">Surname</label>
            <input
                id="surname"
                type="text"
                name="surname"
                onChange={surnameChangeHandler}
                value={enteredSurname}
            />

            <label htmlFor="email">Email</label>
            <input
                id="email"
                type="email"
                name="email"
                onChange={emailChangeHandler}
                value={enteredEmail}
            />

            <ValidationError prefix="Name" field="name" errors={state.errors} />
            <ValidationError
                prefix="Surname"
                field="surname"
                errors={state.errors}
            />
            <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
            />

            <Button
                type="submit"
                className="button mt-8 my-4 py-2 px-12 hover:animate-shake"
                disabled={state.submitting}
            >
                Submit
            </Button>
        </form>
    );
};

CompForm.propTypes = {
    formKey: PropTypes.string.isRequired,
    setError: PropTypes.func.isRequired,
    setSuccess: PropTypes.func.isRequired,
    nameChangeHandler: PropTypes.func.isRequired,
    surnameChangeHandler: PropTypes.func.isRequired,
    emailChangeHandler: PropTypes.func.isRequired,
    enteredName: PropTypes.string.isRequired,
    enteredSurname: PropTypes.string.isRequired,
    enteredEmail: PropTypes.string.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-container {
  margin: 0 1rem;
}

@media (max-width: 568px) {
  .comp__header {
    font-size: 38px;
  }
}

@media (min-width: 819px) and (max-width: 820px) {

  .comp__header,
  .comp__tag {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (min-width: 1023px) and (max-width: 1024px) {
  .custom-container {
    margin: 0;
  }

  .comp__inner {
    margin: 0;
  }

  .comp__header {
    margin-left: 0px;
    margin-right: 0px;
  }

  .comp__tag {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (min-width: 1530px) {
  .custom-container {
    margin: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/container/TsContainer.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;;EAEE;;IAEE,gBAAgB;IAChB,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,SAAS;EACX;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,SAAS;EACX;AACF","sourcesContent":[".custom-container {\n  margin: 0 1rem;\n}\n\n@media (max-width: 568px) {\n  .comp__header {\n    font-size: 38px;\n  }\n}\n\n@media (min-width: 819px) and (max-width: 820px) {\n\n  .comp__header,\n  .comp__tag {\n    margin-left: 0px;\n    margin-right: 0px;\n  }\n}\n\n@media (min-width: 1023px) and (max-width: 1024px) {\n  .custom-container {\n    margin: 0;\n  }\n\n  .comp__inner {\n    margin: 0;\n  }\n\n  .comp__header {\n    margin-left: 0px;\n    margin-right: 0px;\n  }\n\n  .comp__tag {\n    margin-left: 0px;\n    margin-right: 0px;\n  }\n}\n\n@media (min-width: 1530px) {\n  .custom-container {\n    margin: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import britneyImg from "../../../assets/images/competitions/optimised/lg/britney-collection.png";
import ghostImg from "../../../assets/images/competitions/optimised/lg/ghost-collection.png";
// smaller images - unused
import britneySm from "../../../assets/images/competitions/optimised/sm/climaplex-box.png";
import ghostSm from "../../../assets/images/competitions/optimised/sm/wetnwild.png";

export const ImageVariants = {
    1: {
        smallImage: britneySm,
        largeImage: britneyImg,
    },
    2: {
        smallImage: ghostSm,
        largeImage: ghostImg,
    }
};

import React from "react";
import "./SocialIcons.css";

export const SocialIcons = () => {

  return (
    <>
      {/* Social Media Icons */}
      <div className="flex flex-row justify-center mx-auto p-4 pb-8">
        <div className="icons flex">
          <div className="iconCell">
            <a href="https://www.facebook.com/Superdrug">
              <i className="icon fa-brands fa-facebook ml-5"></i>
            </a>
          </div>
          <div className="iconCell">
            <a href="https://twitter.com/superdrug">
              <i className="icon fa-brands fa-twitter ml-5"></i>
            </a>
          </div>
          <div className="iconCell">
            <a href="https://www.youtube.com/user/superdrugloves?sub_confirmation=1">
              <i className="icon fa-brands fa-youtube ml-5"></i>
            </a>
          </div>
          <div className="iconCell">
            <a href="http://instagram.com/superdrug">
              <i className="icon fa-brands fa-instagram ml-5"></i>
            </a>
          </div>
          <div className="iconCell">
            <a href="https://www.pinterest.co.uk/superdrugloves/">
              <i className="icon fa-brands fa-pinterest ml-5"></i>
            </a>
          </div>
          <div className="iconCell">
            <a href="https://www.superdrug.com/snapchat">
              <i className="icon fa-brands fa-snapchat ml-5"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

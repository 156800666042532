import React from "react";
import useHandleKeyDown from "../../../lib/hooks/useHandleKeyDown";
import Button from "./Button";

const ButtonCTA = () => {

  // scroll w offset
  const handleClick = () => {
    const competitionsSection = document.getElementById("competitions");
    competitionsSection.scrollIntoView({ behavior: "smooth" });
    const windowHeight = window.innerHeight;
    const scrollOffset = -windowHeight * 0.12;

    window.scrollTo({
      top: competitionsSection.offsetTop + scrollOffset,
      behavior: "smooth",
    });
  };

  useHandleKeyDown(handleClick);

  return (
    <div className="flex justify-start md:justify-start py-8 pb-0">
      <Button
        onClick={handleClick}
        className="button border-primary text-primary"
      >
        Explore Competitions
      </Button>
    </div>
  );
};

export default ButtonCTA;

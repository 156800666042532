import { useState } from "react";

const useBooleanToggle = (initialState = false) => {
  const [toggle, setToggle] = useState(initialState);

  const handleToggle = () => {
    setToggle((prevToggle) => !prevToggle);
  };

  return [toggle, handleToggle];
};

export default useBooleanToggle;

import React, { useEffect, useState } from "react";
import CompCards from "./CompCards";

const CompetitionDataContainer = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("../../../data/data.json");
        // console.log("response validation:", response);
        const jsonData = await response.json();
        // console.log("jsonData validation:", jsonData);
        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return <CompCards data={data} />;
};

export default CompetitionDataContainer;

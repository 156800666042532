// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card__title {
  color: var(--text-light);
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  border-radius: 4px;
  padding: 4px 10px;
}

.card__description {
  font-size: 14px;
  color: var(--text-light);
  text-align: left;
  padding: 16px 8px;
}

.card__product-links {
    color: var(--text-light);
    font-weight: 600; 
}

.card__product-url {
    color: var(--item-link);
    font-weight: 600;
    font-size: 20px;
}

.card__product-url:hover {
    color: #EA338A;
    cursor: pointer;
}

.card__terms-link {
  text-decoration: underline;
  font-size: 14px;
  text-align: left;
}

.card__button {
    margin-left: 20px;
    margin-top: 20px;
}

.card__button:hover {
    cursor: pointer;
}

.card__button:disabled {
  background-color: var(--item-disabled);
}

.card__form-link {
  margin-top: 12px;
  color: var(--text-light);
  background-color: var(--item-link);
}

.card__form-link:hover {
  color: var(--text-light);
}

.card__terms-link,
.card__checkbox,
.card__form-link {
    margin: 0;
    padding: 12px 0;
}


@media (max-width: 279px) and (min-width: 285px) {
  #compCard {
    padding-left: 0;
    padding-right: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ui/cards/CardContainer.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,wBAAwB;EACxB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;IACI,wBAAwB;IACxB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;AAClB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,gBAAgB;EAChB,wBAAwB;EACxB,kCAAkC;AACpC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;;;IAGI,SAAS;IACT,eAAe;AACnB;;;AAGA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;AACF","sourcesContent":[".card__title {\n  color: var(--text-light);\n  font-weight: 600;\n  font-size: 20px;\n  text-align: left;\n  border-radius: 4px;\n  padding: 4px 10px;\n}\n\n.card__description {\n  font-size: 14px;\n  color: var(--text-light);\n  text-align: left;\n  padding: 16px 8px;\n}\n\n.card__product-links {\n    color: var(--text-light);\n    font-weight: 600; \n}\n\n.card__product-url {\n    color: var(--item-link);\n    font-weight: 600;\n    font-size: 20px;\n}\n\n.card__product-url:hover {\n    color: #EA338A;\n    cursor: pointer;\n}\n\n.card__terms-link {\n  text-decoration: underline;\n  font-size: 14px;\n  text-align: left;\n}\n\n.card__button {\n    margin-left: 20px;\n    margin-top: 20px;\n}\n\n.card__button:hover {\n    cursor: pointer;\n}\n\n.card__button:disabled {\n  background-color: var(--item-disabled);\n}\n\n.card__form-link {\n  margin-top: 12px;\n  color: var(--text-light);\n  background-color: var(--item-link);\n}\n\n.card__form-link:hover {\n  color: var(--text-light);\n}\n\n.card__terms-link,\n.card__checkbox,\n.card__form-link {\n    margin: 0;\n    padding: 12px 0;\n}\n\n\n@media (max-width: 279px) and (min-width: 285px) {\n  #compCard {\n    padding-left: 0;\n    padding-right: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import Nav from "../ui/navigation/Nav";

const Header = () => {
  return (
    <header
      className="header flex justify-between fixed top-0 left-0 h-auto sm:h-16
          bg-transparent w-full animate-fadeDownSlide"
    >
      <Nav />
    </header>
  );
};

export default Header;

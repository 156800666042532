import React from "react";
// import superLogo from "../../assets/logos/logo-Superdrug.avif";
import superLogo from "../../assets/logos/logo-Superdrug.png";
import { SocialIcons } from "../ui/icons/SocialIcons";

const Footer = () => {
  return (
    <footer className="footer bottom-0 left-0 flex flex-col justify-center items-center w-full z-10 border-t-4 border-pink-nav">
      <div className="w-full py-24 lg:py-16 mt-0 bg-background-dark flex flex-col justify-center">
        <div className="footer__content flex flex-col justify-center items-center">
          <a
            href="https://www.superdrug.com/"
            className="flex justify-center items-center"
          >
            <img
              className={`flex justify-center text-center h-12 ml-2 mb-8`}
              src={`${superLogo}`}
              alt="Superdrug Logo"
            ></img>
          </a>
          <ul className="flex justify-center items-center ml-4 mb-6 text-sm sm:mb-0 text-teal-300">
            <li>
              <a
                href={"/pdf/The-Edit-Privacy-Notice-V2.pdf"}
                target="_blank"
                className="mr-4 hover:underline md:mr-6 hover:text-sd-aqua"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href={"/pdf/The-Edit-Site-TCs.pdf"}
                target="_blank"
                className="mr-4 hover:underline md:mr-6 hover:text-sd-aqua"
                rel="noreferrer"
              >
                Terms of use
              </a>
            </li>
            <li>
              <a
                href={
                  "mailto:tobungu@therivergroup.co.uk?cc=sturner@therivergroup.co.uk"
                }
                target="_blank"
                className="mr-4 hover:underline md:mr-6 hover:text-sd-aqua"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </li>
          </ul>
          {/* social media icons component  */}
          <SocialIcons />
          {/* copyright and credits information */}
          {/* Copyright &copy; 2023. Superdrug. All rights reserved. Created with 💖 for The Edit Magazine by Matt Jones. */}
          <p className="text-white text-xs flex justify-center text-center mx-4 sm:mx-8 md:mx-16">
            Copyright &copy; {new Date().getFullYear()}. Superdrug&trade; all
            rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { useEffect } from 'react';

const useHandleKeyDown = (handleEnter) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        handleEnter();
      }
    };

    // const handleEnter = () => {
    //   console.log('Button clicked!');
    // };

    const button = document.querySelector('button');
    button.addEventListener('keydown', handleKeyDown);
    button.addEventListener('click', handleEnter);

    return () => {
      button.removeEventListener('keydown', handleKeyDown);
      button.removeEventListener('click', handleEnter);
    };
    // only run once- if empty
  }, [handleEnter]);

  // return cleanup

};

export default useHandleKeyDown;
